var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "resultStatisticsDiv" },
    [
      _c(
        "Card",
        { ref: "colHeight", staticStyle: { height: "100%" } },
        [
          _c(
            "Row",
            { attrs: { gutter: 10 } },
            [
              _c(
                "Col",
                { attrs: { span: "24" } },
                [
                  _c(
                    "Form",
                    {
                      ref: "queryForm",
                      attrs: { "label-width": 120, inline: "" },
                    },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "体检登记日期" } },
                        [
                          _c("DatePicker", {
                            attrs: {
                              type: "date",
                              transfer: "",
                              placeholder: "开始时间",
                            },
                            on: { "on-change": _vm.datePickerStartTime },
                            model: {
                              value: _vm.queryFrom.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryFrom, "startDate", $$v)
                              },
                              expression: "queryFrom.startDate",
                            },
                          }),
                          _vm._v("  ~  "),
                          _c("DatePicker", {
                            attrs: {
                              type: "date",
                              transfer: "",
                              placeholder: "结束时间",
                            },
                            on: { "on-change": _vm.datePickerEndTime },
                            model: {
                              value: _vm.queryFrom.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryFrom, "endDate", $$v)
                              },
                              expression: "queryFrom.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "科室" } },
                        [
                          _c(
                            "Select",
                            {
                              staticStyle: { width: "185px" },
                              attrs: {
                                transfer: "",
                                clearable: "",
                                "label-in-value": "",
                              },
                              on: {
                                "on-change": _vm.getTDepartResultStatistics,
                              },
                              model: {
                                value: _vm.officeIds,
                                callback: function ($$v) {
                                  _vm.officeIds = $$v
                                },
                                expression: "officeIds",
                              },
                            },
                            _vm._l(_vm.departmentArr, function (item, index) {
                              return _c(
                                "Option",
                                { key: index, attrs: { value: item.id } },
                                [_vm._v(" " + _vm._s(item.title) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "单位" } },
                        [
                          _c(
                            "Select",
                            {
                              staticStyle: { width: "185px" },
                              attrs: {
                                transfer: "",
                                clearable: "",
                                "label-in-value": "",
                              },
                              on: {
                                "on-change": _vm.getTDepartResultStatistics,
                              },
                              model: {
                                value: _vm.queryFrom.dept,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryFrom, "dept", $$v)
                                },
                                expression: "queryFrom.dept",
                              },
                            },
                            _vm._l(_vm.unitArr, function (item, index) {
                              return _c(
                                "Option",
                                { key: index, attrs: { value: item.id } },
                                [_vm._v(" " + _vm._s(item.name) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        {
                          staticClass: "br",
                          staticStyle: { "margin-left": "-35px" },
                        },
                        [
                          _c(
                            "Button",
                            {
                              attrs: { type: "primary", icon: "md-search" },
                              on: { click: _vm.getTDepartResultStatistics },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-american-football-outline",
                              },
                              on: { click: _vm.handleReset },
                            },
                            [_vm._v("重置")]
                          ),
                          _c(
                            "Button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "ios-download-outline",
                              },
                              on: { click: _vm.exportDataNew },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "Row",
            { attrs: { justify: "end" } },
            [
              _c("Table", {
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  loading: _vm.tableLoading,
                  border: "",
                  columns: _vm.columns,
                  sortable: "custom",
                  data: _vm.tableData,
                  "span-method": _vm.handleSpan,
                  "max-height": _vm.height,
                },
              }),
            ],
            1
          ),
          _c("Row", [
            _c("div", { staticStyle: { "margin-top": "20px" } }, [
              _c("span", [_vm._v("合计：")]),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "blue" } },
                [_vm._v("统计科室：" + _vm._s(_vm.totalOfficeCount))]
              ),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "blue" } },
                [_vm._v("总登记人数：" + _vm._s(_vm.totalCountRegist))]
              ),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "blue" } },
                [_vm._v("总检查人数：" + _vm._s(_vm.totalCountHealthy))]
              ),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "blue" } },
                [_vm._v("总检查人次：" + _vm._s(_vm.totalCount))]
              ),
              _c(
                "span",
                { staticStyle: { "margin-left": "20px", color: "blue" } },
                [_vm._v("挂账+自费：￥" + _vm._s(_vm.totalPrice))]
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }